import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
  ReferenceArea,
} from 'recharts';
import { Card, Row, Col, Alert, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import './BatteryScreen.css'; // Assuming you have corresponding CSS

const BatteryScreen = () => {
  const { t } = useTranslation();

  const [batteryData, setBatteryData] = useState([]);
  const [latestCapacity, setLatestCapacity] = useState(null);
  const [timeCharging, setTimeCharging] = useState('0h 0m');
  const [timeDischarging, setTimeDischarging] = useState('0h 0m');
  const [maxSoC, setMaxSoC] = useState(0);
  const [minSoC, setMinSoC] = useState(100);
  const [averageSoC, setAverageSoC] = useState(0);
  const [chargingRate, setChargingRate] = useState(0);
  const [dischargingRate, setDischargingRate] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Helper function to convert minutes to h m format
  const convertMinutes = (minutes) => {
    const h = Math.floor(minutes / 60);
    const m = minutes % 60;
    return `${h}h ${m}m`;
  };

  useEffect(() => {
  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);

      const batteryResponse = await axios.get('https://menea.krtech.dev/api/battery-data');
      const rawBatteryData = batteryResponse.data;
      const homeResponse = await axios.get('https://menea.krtech.dev/api/electricity/home-energy-sources');
      const homeData = homeResponse.data;

      // Sort battery data
      const sortedBatteryData = rawBatteryData.sort((a, b) => new Date(a.DateTime) - new Date(b.DateTime));
      
      const data = sortedBatteryData.map(item => ({
        time: item.DateTime,
        formattedTime: moment(item.DateTime).format('HH:mm'),
        SoCPercentage: parseFloat(item.SoC)
      }));

      if (data.length === 0) {
        setError(t('battery.noDataAvailable'));
        setLoading(false);
        return;
      }

      // Map homeData
      const homeDataMap = new Map();
      homeData.forEach(entry => {
        // Clean and parse the home data time
        const dateTimeClean = entry.dateTime.replace(/"/g, '');
        const homeTime = moment(dateTimeClean, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DDTHH:mm:00Z');
        homeDataMap.set(homeTime, entry.homeUsage);
      });

      const intervalDurationMinutes = 5;
      let chargingIntervals = 0;
      let dischargingIntervals = 0;

      for (let i = 1; i < data.length; i++) {
        const prev = data[i - 1];
        const current = data[i];
        
        const prevSoC = prev.SoCPercentage;
        const currSoC = current.SoCPercentage;

        if (currSoC > prevSoC) {
          // Charging
          chargingIntervals++;
          const batteryTimeKey = moment(current.time).utc().format('YYYY-MM-DDTHH:mm:00Z');
          const homeUsage = homeDataMap.get(batteryTimeKey);
          // Log to verify
          console.log(`At ${batteryTimeKey}, homeUsage:`, homeUsage);

          if (homeUsage !== undefined && homeUsage < 0) {
            current.source = 'Solar';
            console.log('here', homeUsage)
          } else {
            current.source = 'Grid';
          }
        } else if (currSoC < prevSoC) {
          // Discharging
          dischargingIntervals++;
          current.source = 'Discharge';
        } else {
          current.source = 'Idle';
        }
      }

      setBatteryData(data);

      const latest = data[data.length - 1];
      setLatestCapacity(latest.SoCPercentage);

      setTimeCharging(`${Math.floor((chargingIntervals * intervalDurationMinutes) / 60)}h ${(chargingIntervals * intervalDurationMinutes) % 60}m`);
      setTimeDischarging(`${Math.floor((dischargingIntervals * intervalDurationMinutes) / 60)}h ${(dischargingIntervals * intervalDurationMinutes) % 60}m`);

      const socValues = data.map(item => item.SoCPercentage);
      setMaxSoC(Math.max(...socValues));
      setMinSoC(Math.min(...socValues));
      setAverageSoC((socValues.reduce((a,b) => a+b, 0)/socValues.length).toFixed(2));

      const chargeTime = chargingIntervals * intervalDurationMinutes;
      const dischargeTime = dischargingIntervals * intervalDurationMinutes;

      if (chargeTime > 0) {
        setChargingRate((socValues[socValues.length - 1] - socValues[0]) / chargeTime);
      } else {
        setChargingRate(0);
      }

      if (dischargeTime > 0) {
        setDischargingRate((socValues[0] - socValues[socValues.length - 1]) / dischargeTime);
      } else {
        setDischargingRate(0);
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching battery data:', error);
      setError(t('battery.errorFetchingData'));
      setLoading(false);
    }
  };

  fetchData();
}, [t]);

  const containerStyle = {
    flex: 1,
    padding: '20px',
    overflowY: 'auto',
  };

  const chartContainerStyle = {
    width: '100%',
    height: '500px',
  };

  const titleStyle = {
    textAlign: 'center',
    marginBottom: '20px',
  };

  const explanationStyle = {
    textAlign: 'center',
    fontStyle: 'italic',
    marginTop: '10px',
    marginBottom: '20px',
    maxWidth: '90%',
  };

  // Group consecutive charging intervals by source
  const chargingIntervalsGrouped = [];
  if (batteryData.length > 1) {
    let currentGroup = null;

    for (let i = 1; i < batteryData.length; i++) {
      const prev = batteryData[i - 1];
      const curr = batteryData[i];
      if (curr.SoCPercentage > prev.SoCPercentage) {
        // Charging interval
        if (!currentGroup) {
          // Start a new group
          currentGroup = {
            source: curr.source,
            startTime: prev.formattedTime,
            endTime: curr.formattedTime,
            maxSoC: curr.SoCPercentage
          };
        } else {
          // Continue the current group if the source is the same
          if (currentGroup.source === curr.source) {
            currentGroup.endTime = curr.formattedTime;
            // Update maxSoC if needed
            if (curr.SoCPercentage > currentGroup.maxSoC) {
              currentGroup.maxSoC = curr.SoCPercentage;
            }
          } else {
            // Different source: push the old group and start a new one
            chargingIntervalsGrouped.push(currentGroup);
            currentGroup = {
              source: curr.source,
              startTime: prev.formattedTime,
              endTime: curr.formattedTime,
              maxSoC: curr.SoCPercentage
            };
          }
        }
      } else {
        // Not charging at this point, close any open group
        if (currentGroup) {
          chargingIntervalsGrouped.push(currentGroup);
          currentGroup = null;
        }
      }
    }

    // If a group is still open at the end
    if (currentGroup) {
      chargingIntervalsGrouped.push(currentGroup);
    }
  }

  return (
    <div style={containerStyle}>
      <h2 style={titleStyle}>{t('battery.batteryStateOfChargeOverTime')}</h2>

      {loading && (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Spin tip={t('battery.loading')} />
        </div>
      )}

      {error && (
        <Alert
          message={error}
          type="error"
          showIcon
          style={{ marginBottom: '20px' }}
        />
      )}

      {!loading && !error && batteryData.length > 0 && (
        <>
          <Card style={{ marginBottom: '20px' }}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={8}>
                <Card type="inner" title={t('battery.latestCapacity')}>
                  <p style={{ fontSize: '24px', margin: 0 }}>
                    {latestCapacity !== null ? `${latestCapacity}%` : '--'}
                  </p>
                </Card>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <Card type="inner" title={t('battery.maxSoC')}>
                  <p style={{ fontSize: '24px', margin: 0 }}>
                    {maxSoC}%
                  </p>
                </Card>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <Card type="inner" title={t('battery.minSoC')}>
                  <p style={{ fontSize: '24px', margin: 0 }}>
                    {minSoC}%
                  </p>
                </Card>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <Card type="inner" title={t('battery.averageSoC')}>
                  <p style={{ fontSize: '24px', margin: 0 }}>
                    {averageSoC}%
                  </p>
                </Card>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <Card type="inner" title={t('battery.chargingRate')}>
                  <p style={{ fontSize: '24px', margin: 0 }}>
                    {chargingRate.toFixed(2)}%/min
                  </p>
                </Card>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <Card type="inner" title={t('battery.dischargingRate')}>
                  <p style={{ fontSize: '24px', margin: 0 }}>
                    {dischargingRate.toFixed(2)}%/min
                  </p>
                </Card>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <Card type="inner" title={t('battery.timeSpentCharging')}>
                  <p style={{ fontSize: '24px', margin: 0 }}>{timeCharging}</p>
                </Card>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <Card type="inner" title={t('battery.timeSpentDischarging')}>
                  <p style={{ fontSize: '24px', margin: 0 }}>{timeDischarging}</p>
                </Card>
              </Col>
            </Row>
          </Card>

          <div style={chartContainerStyle}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={batteryData}
                margin={{ top: 10, right: 30, left: 20, bottom: 40 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="formattedTime"
                  label={{
                    value: t('battery.timeHHmm'),
                    position: 'insideBottom',
                    offset: -5,
                  }}
                  tick={{ fontSize: 12 }}
                  angle={-45}
                  textAnchor="end"
                  height={80}
                  interval={Math.floor(batteryData.length / 10)}
                />
                <YAxis
                  label={{
                    value: t('battery.SoCPercentage'),
                    angle: -90,
                    position: 'insideLeft',
                    offset: 10,
                  }}
                  domain={[0, 100]}
                  tickFormatter={(value) => `${value}%`}
                />
                <Tooltip
                  labelFormatter={(value) => `${t('battery.time')}: ${value}`}
                  formatter={(value, name) => [`${value.toFixed(2)}%`, t('battery.stateOfCharge')]}
                />
                <Legend verticalAlign="top" height={36} />
                <Line
                  type="monotone"
                  dataKey="SoCPercentage"
                  stroke="#82ca9d"
                  name={t('battery.stateOfChargePercentage')}
                  dot={false}
                />

                {chargingIntervalsGrouped.map((interval, idx) => (
                  <ReferenceArea
                    key={`source-interval-${idx}`}
                    x1={interval.startTime}
                    x2={interval.endTime}
                    y1={0}
                    y2={interval.maxSoC}
                    fill={
                      interval.source === 'Solar'
                        ? 'rgba(255, 215, 0, 0.3)'   // Solar: golden hue
                        : interval.source === 'Grid'
                          ? 'rgba(135, 206, 250, 0.3)' // Grid: light blue
                          : 'transparent'
                    }
                    ifOverflow="extendDomain"
                    label={{
                      value: interval.source,
                      position: 'insideBottom',
                      fill: '#333',
                      fontSize: 12,
                    }}
                  />
                ))}
              </LineChart>
            </ResponsiveContainer>
          </div>

          <p style={explanationStyle}>
            {t('battery.explanation')}
          </p>
        </>
      )}
    </div>
  );
};

export default BatteryScreen;
