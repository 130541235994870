// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';



const resources = {
  en: {
    translation: {
      "peakLoadShaving": {
        "time": "Time",
        "peakLoad": "Peak Load",
        "batteryContribution": "Battery Contribution",
        "peakReduction": "Peak Reduction",
        "peak_load_shaving": "Peak Load Shaving",
        "highlights": "Highlights",
        "maximumGridEnergy": "Maximum Grid Energy",
        "at": "at",
        "totalBatteryEnergyUsed": "Total Battery Energy Used",
        "batteryContributionPercentage": "Battery Contribution Percentage",
        "energyFlow": "Energy Flow",
        "peakShavingLimit": "Peak Shaving Limit",
        "withBattery": "With Battery",
        "withoutBattery": "Without Battery",
        "energySourcesBreakdown": "Energy Sources Breakdown",
        "fromGrid": "From Grid",
        "fromBattery": "From Battery",
        "highlightDay": "Highlight Day 5.10.2024"

      },

      "electricalEnergyConsumption": "Electrical Energy Consumption",
      "todayUsage": "Today's Usage",
      "totalElectricityUsedToday": "Total Electricity Used Today",
      "todayUsageChart": "Today's Usage Chart",
      "homeUsage": "Building Usage",
      "monthlyElectricityUsage": "Monthly Electricity Usage",
      "tariffInformation":"Tarrif Information",
      "higherTariffUsage":"Higher Tariff Usage",
      "lowerTariffUsage":"Lower Tariff Usage",
      "winterTariff": "Winter tarrif",
      "summerTariff": "Summer tarrif",
      "higherTariff":"VT",
      "lowerTariff":"NT",
      "thermalEnergy": {
        "today": "Today",
        "last30Days": "Last 30 Days",
        "lastYear": "Last Year",
        "thermalEnergyConsumption": "Thermal Energy Consumption",
        "pyrolyticFurnace": "Pyrolytic Furnace",
        "heatPump": "Heat Pump",
        "gasConsumption": "Gas Consumption",
        today: 'Today',
        last30Days: 'Last 30 Days',
        lastYear: 'Last Year',
        thermalEnergyConsumption: 'Thermal Energy Consumption',
        dayTariff: 'Day Tariff',
        nightTariff: 'Night Tariff',
        heatpump: 'Heat Pump (in kW)',
        pyrolitic: 'Pyrolitic (in kW)',
        nt: 'Night Tariff',
        vt: 'Day Tariff',
         today: 'Today',
      last30Days: 'Last 30 Days',
      lastYear: 'Last Year',
      thermalEnergyConsumption: 'Thermal Energy Consumption',
      dayTariff: 'Day Tariff',
      nightTariff: 'Night Tariff',
      heatpump: 'Heat Pump (in kW)',
      pyrolitic: 'Pyrolysis (in kW)',
      piroliticPećPlina: 'Pyrolysis Stove Gas Consumption',
      solarni: 'Solar Collectors',
      nt: 'Night Tariff',
      vt: 'Day Tariff',
      },
      "waterConsumption": {
        "loading": "Loading",
        "waterConsumption": "Water Consumption",
        "last24Hours": "Last 24 Hours",
        "last30Days": "Last 30 Days",
        "last12Months": "Last 12 Months"
      },
      "inverter": {
        "loading": "Loading",
        "noData": "No data available",
        "inverterData": "Inverter Data",
        "todayProduction": "Today's Production",
        "monthlyProduction": "Monthly Production",
        "yearlyProduction": "Yearly Production",
        "production": "Production",
        "time": "Time",
        "powerKw": "Power (kW)",
        "power": "Power",
        "date": "Date",
        "energyKwh": "Energy (kWh)",
        "energy": "Energy",
        "month": "Month",
            "loading": "Loading data...",
    "inverterData": "Inverter Data",
    "todayProduction": "Today's Production",
    "highTariff": "High Tariff",
    "lowTariff": "Low Tariff",
    "noData": "No data available",
    "time": "Time",
    "powerKw": "Power (kW)",
    "production": "Production",
    "monthlyProduction": "Monthly Production",
    "yearlyProduction": "Yearly Production",
    "date": "Date",
    "energyKwh": "Energy (kWh)",
    "energy": "Energy"
      },
      "sidebar": {
        "peak_load_shaving": "Peak Load Shaving",
        "electric_energy": "Electric Energy",
        "thermal_energy": "Thermal Energy",
        "water_consumption": "Water Consumption",
        "ev_charging": "EV Charging",
        "inverter": "Solar",
        "battery": "Battery",
        "StariSustav": "Old System"
      },
      "battery": {
        "batteryStateOfChargeOverTime": "Battery State of Charge Over Time",
        "timeHHmm": "Time (HH:mm)",
        "SoCPercentage": "SoC (%)",
        "time": "Time",
        "stateOfCharge": "State of Charge",
        "stateOfChargePercentage": "State of Charge (%)",
        "explanation": "This graph displays the battery's State of Charge (SoC) percentage over time. The SoC reflects the battery's energy level, ranging from 0% (empty) to 100% (full).",

        "batteryStateOfChargeOverTime": "Battery State of Charge Over Time",
        "timeHHmm": "Time (HH:mm)",
        "SoCPercentage": "SoC (%)",
        "stateOfCharge": "State of Charge",
        "stateOfChargePercentage": "SoC Percentage",
        "loading": "Loading...",
        "errorFetchingData": "Error fetching battery data.",
        "noDataAvailable": "No battery data available.",
        "latestCapacity": "Latest Capacity",
        "timeSpentCharging": "Time Spent Charging",
        "timeSpentDischarging": "Time Spent Discharging",
        "time": "Time",
        "explanation": "This chart shows the battery's state of charge (SoC) over time.",
            "batteryStateOfChargeOverTime": "Battery State of Charge Over Time",
    "loading": "Loading...",
    "errorFetchingData": "Error fetching battery data. Please try again later.",
    "noDataAvailable": "No battery data available.",
    "latestCapacity": "Latest Capacity",
    "maxSoC": "Max SoC",
    "minSoC": "Min SoC",
    "averageSoC": "Average SoC",
    "chargingRate": "Charging Rate",
    "dischargingRate": "Discharging Rate",
    "timeSpentCharging": "Time Spent Charging",
    "timeSpentDischarging": "Time Spent Discharging",
    "timeHHmm": "Time (HH:mm)",
    "SoCPercentage": "SoC (%)",
    "stateOfCharge": "State of Charge",
    "stateOfChargePercentage": "State of Charge Percentage",
    "explanation": "The battery state of charge (SoC) shows the current charge level of the battery. It is calculated based on data collected over time."


      }
    }


  },
  hr: {
    translation: {
      "peakLoadShaving": {
        "time": "Vrijeme",
        "peakLoad": "Vršno opterećenje",
        "batteryContribution": "Doprinos baterije",
        "peakReduction": "Smanjenje vršnog opterećenja",
        "peak_load_shaving": "Smanjenje vršnog opterećenja",
        "highlights": "Istaknuto",
        "maximumGridEnergy": "Maksimalna energija iz mreže",
        "at": "u",
        "totalBatteryEnergyUsed": "Ukupno potrošena energija baterije",
        "batteryContributionPercentage": "Postotak doprinosa baterije",
        "energyFlow": "Tok energije",
        "peakShavingLimit": "Granica smanjenja vršnog opterećenja",
        "withBattery": "S baterijom",
        "withoutBattery": "Bez baterije",
        "energySourcesBreakdown": "Raspodjela izvora energije",
        "fromGrid": "Iz mreže",
        "fromBattery": "Iz baterije",
        "highlightDay": "Istaknuto dan 5.10.2024"
      },
      "electricalEnergyConsumption": "Potrošnja Električne Energije",
      "todayUsage": "Današnja Potrošnja",
      "totalElectricityUsedToday": "Ukupno Današnja Potrošnja Električne Energije",
      "todayUsageChart": "Grafikon Današnje Potrošnje",
      "homeUsage": "Potrošnja Zgrade",
      "monthlyElectricityUsage": "Mjesečna Potrošnja Električne Energije",
      "tariffInformation":"Informacije o tarifi",
      "higherTariffUsage":"Korištenje VT",
      "lowerTariffUsage":"Korištenje NT",
      "winterTariff": "Zimska tarifa",
      "summerTariff": "Ljetna tarifa",
      "higherTariff":"VT",
      "lowerTariff":"NT",
      "thermalEnergy": {
        "today": "Danas",
        "last30Days": "Zadnjih 30 Dana",
        "lastYear": "Prošla Godina",
        "thermalEnergyConsumption": "Potrošnja Toplinske Energije",
        "pyrolyticFurnace": "Pirolitička Peć",
        "heatPump": "Toplinska Pumpa",
        "gasConsumption": "Potrošnja Plina",
        today: 'Danas',
        last30Days: 'Posljednjih 30 Dana',
        lastYear: 'Prošla Godina',
        thermalEnergyConsumption: 'Potrošnja Toplinske Energije',
        dayTariff: 'Dan Tarifa',
        nightTariff: 'Noć Tarifa',
        heatpump: 'Dizalica Topline (u W)',
        pyrolitic: 'Pirolitička (u kW)',
        nt: 'Noć Tarifa',
        vt: 'Dan Tarifa',
        piroliticPećPlina: 'Potrošnja Plina',
      solarni: 'Solarni Kolektori',
      nt: 'Noć Tarifa',
      vt: 'Dan Tarifa',


      },
      "waterConsumption": {
        "loading": "Učitavanje",
        "waterConsumption": "Potrošnja Vode",
        "last24Hours": "Zadnja 24 Sata",
        "last30Days": "Zadnjih 30 Dana",
        "last12Months": "Zadnjih 12 Mjeseci"
      },
      "inverter": {
        "loading": "Učitavanje",
        "noData": "Nema dostupnih podataka",
        "inverterData": "Podaci Invertera",
        "todayProduction": "Današnja Proizvodnja",
        "monthlyProduction": "Mjesečna Proizvodnja",
        "yearlyProduction": "Godišnja Proizvodnja",
        "production": "Proizvodnja",
        "time": "Vrijeme",
        "powerKw": "Snaga (kW)",
        "power": "Snaga",
        "date": "Datum",
        "energyKwh": "Energija (kWh)",
        "energy": "Energija",
        "month": "Mjesec",
            "highTariff": "Visoka Tarifa",
    "lowTariff": "Niska Tarifa",

        
      },
      "sidebar": {
        "peak_load_shaving": "Smanjenje Vršnog Opterećenja",
        "electric_energy": "Električna Energija",
        "thermal_energy": "Toplinska Energija",
        "water_consumption": "Potrošnja Vode",
        "ev_charging": "Punjenje EV",
        "inverter": "Fotonaponska elektrana",
        "battery": "Baterija",
        "StariSustav": "Stari Sustav"
      },
      "battery": {
        "batteryStateOfChargeOverTime": "Stanje Napunjenosti Baterije Kroz Vrijeme",
        "timeHHmm": "Vrijeme (HH:mm)",
        "SoCPercentage": "SoC (%)",
        "time": "Vrijeme",
        "stateOfCharge": "Stanje Napunjenosti",
        "stateOfChargePercentage": "Stanje Napunjenosti (%)",
        "explanation": "Ovaj graf prikazuje stanje napunjenosti (SoC) baterije kroz vrijeme. SoC odražava razinu energije baterije, u rasponu od 0% (prazna) do 100% (puna).",
        "batteryStateOfChargeOverTime": "Stanje punjenja baterije kroz vrijeme",
        "timeHHmm": "Vrijeme (HH:mm)",
        "SoCPercentage": "Postotak SoC-a",
        "stateOfCharge": "Stanje punjenja",
        "stateOfChargePercentage": "Postotak stanja punjenja",
        "loading": "Učitavanje...",
        "errorFetchingData": "Pogreška pri dohvaćanju podataka o bateriji.",
        "noDataAvailable": "Nema dostupnih podataka o bateriji.",
        "latestCapacity": "Najnoviji kapacitet",
        "timeSpentCharging": "Vrijeme punjenja",
        "timeSpentDischarging": "Vrijeme pražnjenja",
        "time": "Vrijeme",
        "explanation": "Ovaj grafikon prikazuje stanje punjenja baterije (SoC) kroz vrijeme.",
            "batteryStateOfChargeOverTime": "Status punjenja baterije tijekom vremena",
    "loading": "Učitavanje...",
    "errorFetchingData": "Greška pri dohvaćanju podataka o bateriji. Pokušajte ponovno kasnije.",
    "noDataAvailable": "Nema podataka o bateriji.",
    "latestCapacity": "Posljednji kapacitet",
    "maxSoC": "Maksimalni SoC",
    "minSoC": "Minimalni SoC",
    "averageSoC": "Prosječni SoC",
    "chargingRate": "Brzina punjenja",
    "dischargingRate": "Brzina pražnjenja",
    "timeSpentCharging": "Vrijeme provedeno na punjenju",
    "timeSpentDischarging": "Vrijeme provedeno na pražnjenju",
    "timeHHmm": "Vrijeme (HH:mm)",
    "SoCPercentage": "SoC (%)",
    "stateOfCharge": "Status punjenja",
    "stateOfChargePercentage": "Postotak statusa punjenja",
    "explanation": "Status punjenja baterije (SoC) prikazuje trenutni stupanj napunjenosti baterije. Izračunava se na temelju podataka prikupljenih tijekom vremena."


      }

    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'hr', // Default language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;